/*
 * @Author: Alan
 * @Date: 2023-02-12 20:37:42
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-07 09:30:43
 * @Description: 启动游戏组件（所有关于启动游戏皆用此组件）
 * @FilePath: /F1-M1-WEB-Code/components/Games/openGame.js
 */
import React from "react";
import { message, Modal, Button } from "antd";
import Router from "next/router";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from "$ACTIONS/TlcRequest";
import Tag from "@/Games/Tag";
import { showSpin, hideSpin } from "../../store/spinSlice";
import { connect } from "react-redux";
import ImageWithFallback from "@/ImageWithFallback";
import classNames from "classnames";
import { translate } from "$ACTIONS/Translate";
import GameStatic from "$DATA/game.static";
import { isWindowOpenGame, isCookieNoOpenGame } from "$ACTIONS/constantsData";
import { getQueryVariable, isGuest, isThisAllowGuestOpenGame, isThisAllowGuestOpenGCategory } from "$ACTIONS/helper";
import { gameActions } from "$STORE/gameSlice";
import { getProviderRouteName } from "../../actions/util";
import { gameCollection } from "$DATA/lib/DataList";

class openGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentList: [], // 当前显示的数据
        };
        if (props.OnRef) {
            props.OnRef(this);
        }
    }
    componentDidMount() {}

    getRouterInfo(gameCatCode, provider) {
        const routerPath = GameStatic.find((game) => game.providerName === gameCatCode)?.path;
        const subGameRoute = getProviderRouteName(gameCatCode, provider);
        return { routerPath, subGameRoute };
    }
    
    /**
     * @description: 启动游戏
     * @param {*} items 游戏参数
     * @return {*}
     */
    openGame(items,isClear) {
        console.log("🚀 ~ openGame ~ openGame ~ items:", items)
        let IS_GUEST = isGuest();
        let IS_LOGINNED_MEMBER = !IS_GUEST;
        let IS_ALLOW_GUEST_OPEN_IFRAME_OR_NEW_WINDOW_GAME = isThisAllowGuestOpenGame(items.gameCatCode, items.provider);
        let IS_ALLOW_GUEST_OPEN_GCATEGORY = isThisAllowGuestOpenGCategory(items.gameCatCode, items.provider);
        let gameVendor = items?.gameCatCode;
        const providerRouteName = getProviderRouteName(gameVendor, items.provider)	
		const gameTypeRouteName = gameCollection.find(item=>item.productCode=== gameVendor).name;
        console.log("gameTypeRouteName",gameTypeRouteName, providerRouteName);
        console.log("GameStatic",GameStatic, items)

        //如果未登录，弹出登录窗口 //點擊產品頁平台，跳转游戏大厅游戏列表页面不弹出登录窗口
        if (IS_GUEST && !(items.OpenGamesListPage && IS_ALLOW_GUEST_OPEN_GCATEGORY) && !IS_ALLOW_GUEST_OPEN_IFRAME_OR_NEW_WINDOW_GAME) {
            global.goUserSign("1");
            return;
        }

        //检测账户是否封锁
        if (IS_LOGINNED_MEMBER && JSON.parse(localStorage.getItem("memberInfo"))?.isGameLock) {
            message.error(translate("您的账户已根据账户管理政策关闭了,请联系在线客服"));
            return;
        }
        //跳转游戏大厅游戏列表页面
        if ((items.provider == "VTG" && items.Type == "HeaderMenu") || items.OpenGamesListPage) {
            const { routerPath, subGameRoute } = this.getRouterInfo(items.gameCatCode, items.provider);
            if(routerPath && subGameRoute){
                Router.push(`/${routerPath}/${subGameRoute}`);
                return;
            }
        }

        if (
            typeof SelfExclusionsisDisabled != "undefined" &&
            SelfExclusionsisDisabled
        ) {
            CheckSelfExclusions(true);
            return;
        }
        this.actionGame(items,false,isClear);
    }

    /**
     * @description: 游戏跳转
     * @return {*}
     */
    actionGame(items) {
        console.log("🚀 ~ openGame ~ actionGame ~ items:", items);
        if (items.provider == "AVIATOR" || items.launchGameCode == "aviator") {
            // 启动飞行游戏
            this.OpenSPR();
            return;
        }
        const { routerPath, subGameRoute } = this.getRouterInfo(items.gameCatCode, items.provider);
        let sportsPrams = "";
        if (items?.provider === "IPSB" && items.event_Id && items.sport_Id) {
            sportsPrams = `&event_Id=${items.event_Id}&sport_Id=${items.sport_Id}`;
        }
 
        if (routerPath && subGameRoute) {
            Router.push(`/${routerPath}/${subGameRoute}/?gameid=${items.gameId}&launchGameCode=${items.launchGameCode || ""}${sportsPrams}`);
        }
    }
    
    //不支持ifame
    IsNoIframe(provider) {
        let thirdPartyCookie = JSON.parse(
            localStorage.getItem("thirdPartyCookie")
        );
        if (
            (thirdPartyCookie == false &&
                isCookieNoOpenGame.find((v) => v == provider)) ||
            isWindowOpenGame.find((v) => v == provider)
        ) {
            return true;
        }
        return false;
    }

    /**
     * @description: 开启飞行游戏
     * @return {*}
     */
    OpenSPR() {
        const { showSpin, hideSpin } = this.props;
        showSpin([true, translate("加载中")]);
        get(
            ApiPort.CmsGames +
            `?gameType=InstantGames&gameSortingType=Default&provider=SPR&api-version=2.0&Platform=Desktop`
        ).then((res) => {
            hideSpin();
            if (res.result && res.result.gameDetails.length != 0) {
                let item = res.result.gameDetails.find(
                    (ele) => ele.launchGameCode === "aviator"
                );
                if (item) {
                    Router.push(`/arcade-games/spribe/?gameid=${item.gameId}&launchGameCode=${item.launchGameCode || ""}`);
                } else {
                    Modal.info({
                        className: "confirm-modal-of-public oneButton elementTextLeft",
                        icon: null,
                        okText: translate("同意"),
                        title: translate("通知"),
                        content: res.message || translate("您打开的游戏正在维护中，请稍后再回来。 如果您有任何疑问，请联系在线聊天以获得支持"),
                        centered: true
                    });
                }
            }
        });
    }

    render() {
        const { itemsData, customHtml, hideTag, QuickStartGame, className } =
        this.props;
        const getImageDimensions = (gameCatCode) => {
            switch (gameCatCode) {
                case "P2P":
                    return { width: 349, height: 216 };
                case "LiveCasino":
                    return { width: 358, height: 220 };
                case "Slot":
                    return { width: 294, height: 181 };
                default:
                    return { width: 400, height: 300 };
            }
        };
        return (
            <React.Fragment>
                {!QuickStartGame && (
                    <React.Fragment>
                        {/* 头部导航栏启动 */}
                        {itemsData &&
                            itemsData.Type &&
                            itemsData.Type == "HeaderMenu" && (
                                <a
                                    onClick={() => {
                                        this.openGame(itemsData);
                                    }}
                                    className={classNames({
                                        isNew: itemsData.isNew,
                                        isHot: itemsData.isHot,
                                    })}
                                >
                                    <div
                                        className={classNames({
                                            ["list-content"]: true,
                                        })}
                                    >
                                        <ImageWithFallback
                                            src={itemsData.image||"/vn/img/icon/bluebg.png"}
                                            width={100}
                                            height={100}
                                            alt={itemsData.gameName}
                                            fallbackSrc="/vn/img/icon/bluebg.png"
                                        />
                                        <p>{itemsData.gameName}</p>
                                    </div>
                                </a>
                            )}
                        {/* 普通游戏图片启动 */}
                        {itemsData && !itemsData.Type && !customHtml && (
                            <div
                                className="openGame"
                                onClick={() => {
                                    this.openGame(itemsData);
                                }}
                            >
                                {!hideTag && (
                                    <Tag item={itemsData}/>
                                )}
                                <ImageWithFallback
                                    src={itemsData.imageUrl||"/vn/img/logo/logo.svg"}
                                    width={
                                        getImageDimensions(
                                            itemsData.gameCatCode
                                        ).width
                                    }
                                    height={
                                        getImageDimensions(
                                            itemsData.gameCatCode
                                        ).height
                                    }
                                    alt={itemsData.gameName}
                                    fallbackSrc="/vn/img/logo/logo.svg"
                                    fixRatio={true}
                                />
                                <div className="GameName">
                                    {itemsData.gameName}
                                </div>
                            </div>
                        )}
                        {/* 自定义显示 */}
                        {customHtml && customHtml(this)}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = function(dispatch){
    return{
        showSpin, 
        hideSpin,
    } 
};

export default connect(null, mapDispatchToProps)(openGame);
