/**
 * home header每一项游戏 piwik
 * @param {*} gameCatCode  游戏类型
 * @param {*} providerCode  游戏code
 * @param {*} actionType 
 */
export function SetPiwikData(gameCatCode, providerCode, actionType) {
    console.log("🚀 ~ file: piwikData.js:8 ~ SetPiwikData ~ gameCatCode:", gameCatCode)
    if (gameCatCode === "Sportsbook") {
        switch (providerCode) {
            case "SBT":
                Pushgtagdata("Sports_TopNav", "Launch BTi​", "Sports_TopNav_C_BTi​");
                break;
            case "IPSB":
                Pushgtagdata("Sports_TopNav", "Launch IM", "Sports_TopNav_C_IM");
                break;
            case "OWS":
                Pushgtagdata("Sports_TopNav", "Launch Saba", "Sports_TopNav_C_Saba");
                break;
            case "CML":
                Pushgtagdata("Sports_TopNav", "Launch CMD", "Sports_TopNav_C_CMD");
                break;
            case "VTG":
                Pushgtagdata("Sports_TopNav", "Go to V2 Lobby", "Sports_TopNav_C_V2");
                break;
            default:
                break;
        }
    }

    if (gameCatCode?.toLowerCase() === "esports") {
        switch (providerCode) {
            case "IPES":
                Pushgtagdata("ESports_TopNav​","Launch Fun88 Esports​","ESports_TopNav_C_Fun88");
                break;
            case "TFG":
                Pushgtagdata("ESports_TopNav​", "Launch TF​", "Esports_TopNav_C_TF");
                break;
            default:
                break;
        }
    }
    if (gameCatCode === "InstantGames") {
        switch (providerCode) {
            case "SPR":
                Pushgtagdata("InstantGames_TopNav​​", "Go to Spribe Lobby​", "InstantGames_TopNav_C_Spribe​");
                break;
            case "AVIATOR":
                Pushgtagdata("InstantGames_TopNav​", "Launch Game Aviator​", "InstantGames_TopNav_C_Aviator​");
                break;

            default:
                break;
        }
    }
    if (gameCatCode === "LiveCasino") {
        switch (providerCode) {
            case "EVO":
                Pushgtagdata("LiveDealer_TopNav​", "Go to EVO Palace Lobby​", "LiveDealer_TopNav_C_EVO_Palace");
                break;
            case "GPI":
                Pushgtagdata("LiveDealer_TopNav​","Go to Fun88 Palace Lobby​","LiveDealer_TopNav_C_Fun88_Palace​");
                break;
            case "AGL":
                Pushgtagdata("LiveDealer_TopNav​", "Go to ROYAL Palace​ Lobby​​", "LiveDealer_TopNav_C_ROYAL_Palace​​");
                break;
            case "NLE":
                Pushgtagdata("LiveDealer_TopNav​","Go to HAPPY Palace​ Lobby​​","LiveDealer_TopNav​​_C_HAPPY_Palace​​​");
                break;
            case "SXY":
                Pushgtagdata("LiveDealer_TopNav​", "Go to S Palace​ Lobby​", "LiveDealer_TopNav_C_S_Palace​​");
                break;
            case "EBT":
                Pushgtagdata("LiveDealer_TopNav​", "Go to E Palace Lobby​", "LiveDealer_TopNav_C__E_Palace​​​");
                break;
            case "WEC":
                Pushgtagdata("LiveDealer_TopNav​", "Go to WE Palace Lobby​", "LiveDealer_TopNav_C_WE​");
                break;
            case "WMC":
                Pushgtagdata("LiveDealer_TopNav​", "Go to WM Palace​ Lobby​​", "LiveDealer_TopNav​​_C_WM_Palace​​​");
                break;
            case "TG":
                Pushgtagdata("LiveDealer_TopNav​", "Go to PP Palace​ Lobby​​", "LiveDealer_TopNav_C_PP_Palace​​​​");
                break;
            case "AEC​​":
                Pushgtagdata("LiveDealer_TopNav​", "Go to S Palace​ Lobby​​​", "LiveDealer_TopNav​​_C_S_Palace​​​");
                break;
            case "DGG​":
                Pushgtagdata("LiveDealer_TopNav​", "Go to DG Palace​ Lobby​​​", "LiveDealer_TopNav​​_C_DG_Palace​​​");
                break;
            default:
                break;
        }
    }
    if (gameCatCode === "P2P") {
        switch (providerCode) {
            case "TGP":
                Pushgtagdata("P2P_TopNav​", "Launch Game Viet", "P2P_TopNav_C_Game_Viet​​");
                break;
            case "KPK":
                Pushgtagdata("P2P_TopNav​", "Launch King Porker​​", "P2P_TopNav_C_King_Porker​​");
                break;
            default:
                break;
        }
    }
    if (gameCatCode === "Slot") {
        switch (providerCode) {
            case "JIR":
                Pushgtagdata("SlotFishing_TopNav", "Go to Jili Lobby​", "SlotFishing_TopNav_C_Jili​");
                break;
            case "JIF":
                Pushgtagdata("SlotFishing_TopNav​", "Go to FishingJili Lobby", "SlotFishing_TopNav_C_FishingJili");
                break;
            case "TG":
                Pushgtagdata("SlotFishing_TopNav​", "Go to PP Lobby", "SlotFishing_TopNav_C_PP");
                break;
            case "MGP":
                Pushgtagdata("SlotFishing_TopNav​", "Go to MGS Lobby", "SlotFishing_TopNav_C_MGS");
                break;
            case "BSG":
                Pushgtagdata("SlotFishing_TopNav", "Go to BSG Lobby", "SlotFishing_TopNav_C_BSG");
                break;
            case "PGS":
                Pushgtagdata("SlotFishing_TopNav", "Go to PG Lobby", "SlotFishing_TopNav_C_PG");
                break;
            case "IMOPT":
                Pushgtagdata("SlotFishing_TopNav", "Go to PT Lobby", "SlotFishing_TopNav_C_PT");
                break;
            case "CQG":
                Pushgtagdata("SlotFishing_TopNav", "Go to CQ9 Lobby", "SlotFishing_TopNav_C_CQ9");
                break;
            case "PNG":
                Pushgtagdata("SlotFishing_TopNav​", "Go to PNG Lobby", "SlotFishing_TopNav_C_PNG​");
                break;
            case "SWF":
                Pushgtagdata("SlotFishing_TopNav", "Go to SW Lobby", "SlotFishing_TopNav_C_SW");
                break;
            case "SPG":
                Pushgtagdata("SlotFishing_TopNav​", "Go to SG Lobby", "SlotFishing_TopNav_C_SG");
                break;
            case "HBN":
                Pushgtagdata("SlotFishing_TopNav​", "Go to HBN Lobby", "SlotFishing_TopNav_C_HBN");
                break;
            case "EVP":
                Pushgtagdata("SlotFishing_TopNav​", "Go to EVP Lobby", "SlotFishing_TopNav_C_EVP");
                break;
            case "BNG":
                Pushgtagdata("SlotFishing_TopNav​", "Go to BNG Lobby", "SlotFishing_TopNav_C_BNG");
                break;
            default:
                break;
        }
    }
    if (gameCatCode === "KenoLottery") {
        switch (providerCode) {
            case "TCG":
                Pushgtagdata("Lottery_TopNav​", "Go to TC Lobby​", "Lottery_TopNav_C_TC​");
                break;
            case "SGW":
                Pushgtagdata("Lottery_TopNav", "Go to SGW Lobby", "Lottery_TopNav_C_SGW");
                break;
            case "SLC":
                Pushgtagdata("Lottery_TopNav", "Go to SLC Lobby", "Lottery_TopNav_C_SLC");
                break;
            case "GPK":
                Pushgtagdata("Lottery_TopNav", "Go to GPK Lobby", "Lottery_TopNav_C_GPK");
                break;
            default:
                break;
        }
    }
}

//Header 点击菜单中的每个游戏的 piwik
export const HeaderGameNavPiwik = (code) => {
    switch (code) {
        case "Sportsbook":
            Pushgtagdata("Home_TopNav", "Go to Sports Listing", "Home_TopNav_C_Sports")
            break;
        case "ESports":
            Pushgtagdata("Home_TopNav", "Go to Esports Listing", "Home_TopNav_C_Esports")
            break;
        case "InstantGames":
            Pushgtagdata("Home_TopNav", "Go to Instant Game Listing", "Home_TopNav_C_InstantGames")
            break;
        case "LiveCasino":
            Pushgtagdata("Home_TopNav", "Go to LD Listing", "Home_TopNav_C_LiveDealer")
            break;
        case "Slot":
            Pushgtagdata("Home_TopNav", "Go to Slot/Fishing Listing", "Home_TopNav_C_SlotFishing")
            break;
        case "P2P":
            Pushgtagdata("Home_TopNav", "Go to P2P Listing", "Home_TopNav_C_P2P")
            break;
        case "KenoLottery":
            Pushgtagdata("Home_TopNav", "Go to Lottery Listing", "Home_TopNav_C_Lottery")
            break;
        case "promotions":
            Pushgtagdata("Home_TopNav", "Go to Promotion", "Home_TopNav_C_Promotion")
            break;
        case "KingsClub":
            Pushgtagdata("Home_TopNav", "Go to Rewards", "Home_TopNav_C_Rewards")
            break;
        case "Alliance":
            Pushgtagdata("Home_TopNav", "Go to Affiliate Page", "Home_TopNav_C_Affiliate")
            break;
        case "dailyGift":
            Pushgtagdata("Home_TopNav", "Go to Daily Deal", "Home_TopNav_C_DailyDeal")
            break;
        default:
            break;
    }
}


//Header 点击菜单中各个游戏的more按钮 piwik
export const HeaderGameNavMoreBtnPiwik = (code) => {
    switch (code) {
        case "Sportsbook":
            Pushgtagdata("Sports_TopNav", "Go to Sports Listing", "Sports_TopNav_C_Listing")
            break;
        case "ESports":
            Pushgtagdata("Esports_TopNav", "Go to Esports Listing", "Esports_TopNav_C_Listing")
            break;
        case "InstantGames":
            Pushgtagdata("InstantGames_TopNav", "Go to InstantGames Listing", "InstantGames_TopNav_C_Listing")
            break;
        case "LiveCasino":
            Pushgtagdata("LiveDealer_TopNav", "Go to LiveDealer Listing", "LiveDealer_TopNav_C_Listing​")
            break;
        case "Slot":
            Pushgtagdata("SlotFishing_TopNav", "Go to Slots Listing", "SlotFishing_TopNav_C_Listing​")
            break;
        case "P2P":
            Pushgtagdata("P2P_TopNav", "Go to P2P Listing​​", "P2P_TopNav_C_Listing​")
            break;
        case "KenoLottery":
            Pushgtagdata("Lottery_TopNav", "Go to Lottery Listing", "Lottery_TopNav_C_Listing")
            break;
        default:
            break;
    }
}

/**
 * 游戏列表内选择左边的游戏供应商按钮 piwik
 * @param {*} providerCode  游戏
 * @param {*} gameType 筛选类型
 */
export const gameLobbyFilterVenderPiwik =(providerCode,gameType) => {
    console.log("🚀 ~ file: piwikData.js:779 ~ gameLobbyFilterVenderPiwik ~ providerCode:", providerCode,",",gameType)
    switch (providerCode) {
        case "Sportsbook":
            Pushgtagdata(
                "V2Sports_Lobby", 
                "Filter Game​", 
                "V2Sports_Lobby_C_GameType​",
                "",
                [
                    {customVariableKey: "V2Sports_Lobby_C_GameType​",
                    customVariableValue: gameType}
                ]
            )
            break;
        case "InstantGames":
            Pushgtagdata(
                "InstantGame_Lobby",
                "Filter Vendor​",
                `InstantGame_Lobby_C_Vendor​`,
                "",
                [
                    {customVariableKey: `InstantGame_Lobby_C_VendorName​​`,
                    customVariableValue: gameType},
                ]
            );
            break;
        case "LiveCasino":
            Pushgtagdata(
                "LiveDealer_Lobby",
                `Filter Vendor` ,
                `LiveDealer_Lobby_C_Vendor​`,
                "",
                [
                    {customVariableKey: `LiveDealer_Lobby_C_VendorName​`,
                    customVariableValue: gameType},
                ]
            );
            break; 
        case "P2P":
            Pushgtagdata(
                "P2P_Lobby",
                `Filter Vendor` ,
                `P2P_Lobby_C_Vendor​`,
                "",
                [
                    {customVariableKey: `P2P_Lobby_C_VendorName​`,
                    customVariableValue: gameType},
                ]
            );
            break; 
        case "Slot":
            Pushgtagdata(
                "SlotFishing_Lobby",
                "Filter Vendor​",
                `SlotFishing_Lobby_C_Vendor​`,
                "",
                [
                    {customVariableKey: `SlotFishing_Lobby_C_VendorName`,
                    customVariableValue: gameType},
                ]
            );
            break;
        case "KenoLottery":
            Pushgtagdata(
                "Lottery_Lobby",
                "Filter Vendor",
                `Lottery_Lobby_C_Vendor​`,
                "",
                [
                    {customVariableKey: `Lottery_Lobby_C_VendorName`,                       
                    customVariableValue: gameType},
                ]
            );
            break;
        default:
            break
    }
}

//游戏大厅内点击游戏banner
export const gameLobbyPageBannerPiwik =(gameCatCode,providerCode,providerName) => {
    switch(gameCatCode){
        case "Sportsbook":
            Pushgtagdata(
                "SportsListing​",
                `Launch ${providerName}`, 
                `Sports_Listing_C_Vendor`,
                "",
                [
                    {customVariableKey: `Sports_Listing_C_VendorName​​​`,
                    customVariableValue: providerName},
                ]
            )
            break;
        case "ESports":
            Pushgtagdata("ESports_Listing​",`Launch ${providerName} Game​`, `ESports_Listing_C_${providerName}​`)
            break;
        default:
            break
    }
}
/**
 * 游戏大厅内立即游戏按钮 piwik
 * @param {*} gameCatCode 
 * @param {*} providerCode 
 */
export const gameLobbyOpenGamePiwik =(gameCatCode, providerCode)=> {
    console.log("🚀 ~ file: piwikData.js:805 ~ gameLobbyOpenGamePiwik ~ gameCatCode, providerCode:", gameCatCode, providerCode)

    switch(gameCatCode){
        case "Sportsbook":
            Pushgtagdata("SportsListing​",`Launch ${providerCode} Game​`, `Sports_Listing_C_Enter_${providerCode}_Game​`)
            break;
        case "ESports":
            Pushgtagdata("ESports_Listing​",`Launch ${providerCode} Game​`, `ESports_Listing_C_Enter_${providerCode}_Game​`)
            break;
        default:
            break
    }
}

/**
 * 游戏列表页（无搜索游戏功能页面、推荐游戏栏）立即游戏按钮 piwik
 * @param {*} item 
 * @param {*} gameCatCode //Slots
 */
export const gameListOpenGamePiwik =(item,gameCatCode) => {
    console.log("🚀 ~ file: piwikData.js:826 ~ gameListOpenGamePiwik ~ item:", item,gameCatCode)
    switch(gameCatCode){
        case "InstantGames":
            Pushgtagdata(
                "InstantGame_Lobby",
                `Launch ${item.gameName}` ,
                `InstantGame_Lobby_C_Spribe_Game​`,
                "",
                [
                    {customVariableKey: `InstantGame_Lobby_C_Spribe_GameName`,
                    customVariableValue: item.gameName},
                ]
            );
            break;
        case "LiveCasino":
            Pushgtagdata(
                "LiveDealer_Listing",
                `Launch ${item.gameName}` ,
                `LiveDealer_Listing_C_Recommend_${item.provider}_Game​`,
                "",
                [
                    {customVariableKey: `LiveDealer_Listing_C_Recommend_${item.provider}_GameName`,
                    customVariableValue: item.gameName},
                ]
            );
            break; 
        case "P2P":
            Pushgtagdata(
                "P2P_Listing​",
                `Launch ${item.gameName}` ,
                `P2P_Listing_C_Recommend_${item.provider}_Game​`,
                "",
                [
                    {customVariableKey: `P2P_Listing_C_Recommend_${item.provider}_GameName`,
                    customVariableValue: item.gameName},
                ]
            );
            break; 
        case "Slot":
            Pushgtagdata(
                "SlotFishing_Listing​",
                `Launch ${item.gameName}` ,
                `SlotFishing_Listing_C_Recommended_${item.provider}_Game​`,
                "",
                [
                    {customVariableKey: `Slot_Listing_C_Recommended_${item.provider}_GameName`,
                    customVariableValue: item.gameName},
                ]
            );
            break; 
        case "KenoLottery":
            Pushgtagdata(
                "Lottery_Listing​",
                `Launch ${item.gameName}` ,
                `Lottery_Listing_C_Recommended_${item.provider}_Game​`,
                "",
                [
                    {customVariableKey: `Lottery_Listing_C_Recommended_${item.provider}_GameName`,
                    customVariableValue: item.gameName},
                ]
            );
            break; 
        default:
            break;
    }
}
/**
 * 游戏列表页（可搜索游戏页）立即开始游戏Piwik
 * @param {*} item 
 */
export const  gameListAtFilterOpenGamePiwik =(item,Routerpath) => {
    console.log("🚀 ~ gameListAtFilterOpenGamePiwik ~ item:", item)
    switch(Routerpath){
        case "Sportsbook":
            Pushgtagdata(
                "V2Sports_Lobby",
                `Launch ${item.gameName}`,
                `V2Sports_Lobby_C_${item.provider}_Game​`,
                "",
                [
                    {customVariableKey: `V2Sports_Lobby_C_${item.provider}_GameName`,
                    customVariableValue: item.gameName},
                ]
            );
            break;
        case "InstantGames":
            Pushgtagdata(
                "InstantGame_Lobby",
                `Launch ${item.gameName}` ,
                `InstantGame_Lobby_C_${item.provider}_Game​`,
                "",
                [
                    {customVariableKey: `InstantGame_Lobby_C_${item.provider}_GameName`,
                    customVariableValue: item.gameName},
                ]
            );
            break; 
        case "LiveCasino":
            Pushgtagdata(
                "LiveDealer_Lobby​",
                `Launch ${item.gameName}` ,
                `LiveDealer_Lobby_C_${item.provider}_Game​`,
                "",
                [
                    {customVariableKey: `LiveDealer_Lobby_C_${item.provider}_Game`,
                    customVariableValue: item.gameName},
                ]
            );
            break; 
        case "P2P":
            Pushgtagdata(
                "P2P_Lobby​",
                `Launch ${item.gameName}` ,
                `P2P_Lobby_C_${item.provider}_Game​`,
                "",
                [
                    {customVariableKey: `P2P_Lobby_C_${item.provider}_GameName`,
                    customVariableValue: item.gameName},
                ]
            );
            break; 
        case "Slot":
            Pushgtagdata(
                "SlotFishing_Lobby​",
                `Launch ${item.gameName}` ,
                `SlotFishing_Lobby_C_${item.provider}_Game​`,
                "",
                [
                    {customVariableKey: `SlotFishing_Lobby_C_${item.provider}_GameName`,
                    customVariableValue: item.gameName},
                ]
            );
            break; 
        case "KenoLottery":
            Pushgtagdata(
                "Lottery_Lobby​",
                `Launch ${item.gameName}` ,
                `Lottery_Lobby_C_${item.provider}_Game​`,
                "",
                [
                    {customVariableKey: `Lottery_Lobby_C_${item.provider}_GameName`,
                    customVariableValue: item.gameName},
                ]
            );
            break; 
        default:
            break;
    }
}

/**
 * 游戏列表页面（有搜索游戏功能页） title 和 url 的piwik
 * @param {*} item 
 */
export const gameLobbyPageTrackingPiwik =(item)=> {
    console.log("🚀 ~ gameLobbyPageTrackingPiwik ~ item:", item)
    switch (item){
        case "LiveCasino":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`live_dealer_lobby​​`,`LiveDealer​ Lobby​​`);
            break;
        case "InstantGames":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`instantgames_lobby​`,`InstantGames Lobby​​​​`);
            break;
        case "Sportsbook":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`v2_sports_lobby​​​`,`V2 Sports Lobby​​​`);
            break;
        case "P2P":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`p2p_lobby​​​​`,`P2P​ Lobby​​​​​​`);
            break;
        case "Slot":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`slotfishing_lobby​​`,`SlotFishing​ Lobby​​​​​​`);
            break;
        case "KenoLottery":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`lottery_lottery​`,`Lottery​ Lottery​`);
            break;
        default:
            break;
    }
}

/**
 * 游戏列表页面（无搜索游戏功能页） title 和 url 的piwik
 * esports 没有列表页页面
 * sports 只有v2体育有列表页面
 * @param {*} item 
 */
 export const gameListPageTrackingPiwik =(item)=> {
    console.log("🚀 ~ gameListPageTrackingPiwik ~ item:", item)
    switch (item){
        case "ESports":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`esports​_listing​​​`,`Esports Listing​​​`);
            break;
        case "LiveCasino":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`live_dealer_listing​​`,`LiveDealer Listing​​`);
            break;
        case "InstantGames":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`instantgames_lobby​​`,`InstantGames Lobby​​`);
            break;
        case "Sportsbook":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`sports_​listing​`,`Sports Listing​​​`);
            break;
        case "P2P":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`p2p_listing​​`,`P2P​ Listing​​​`);
            break;
        case "Slot":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`slotfishing_listing​​`,`SlotFishing​ Listing​​​​`);
            break;
        case "KenoLottery":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`lottery_listing​​`,`Lottery​ Listing​​​​`);
            break;
        default:
            break;
    }
}

/**
 * 游戏列表页点击more piwik
 */
 export const gameListPageCheckMorePiwik =(path)=> {
    switch (path){
        case "LiveCasino":
            Pushgtagdata(
                "LiveDealer_Listing​",
                "Go to LiveDealer Lobby​" ,
                `LiveDealer_Listing_C_More​`
            );
            break; 
        case "P2P":
            Pushgtagdata(
                "P2P_Listing​",
                "Go to P2P Lobby​" ,
                `P2P_Listing_C_More​`
            );
            break; 
        case "Slot":
            Pushgtagdata(
                "SlotFishing_Listing​",
                "Go to Slot Fishing Lobby​" ,
                `SlotFishing_Listing_C_More​​`
            );
            break;
        case "KenoLottery":
            Pushgtagdata(
                "Lottery_Listing​",
                "Go to Lottery Lobby​" ,
                `Lottery_Listing_C_More​​`
            );
            break;
        default:
            break;
    }
}

/*游戏列表页、游戏平台列表 PIWIK
* @param {*} gameCatCode  游戏类型code
* @param {*} providerCode  子类code
* @param {*} providerId    子类id
*/
export const gameListPageGamesPlatform =(gameCatCode, providerCode, providerId)=> {
    switch(gameCatCode){
        case "LiveCasino":
            Pushgtagdata(
                "LiveDealer_Listing​",
                `Launch ${providerCode}` ,
                `LiveDealer_Listing_C_Vendor`,
                "",
                [
                    {customVariableKey: `LiveDealer_Listing_C_Vendor`,
                    customVariableValue: providerCode},
                ]
            );
            break;
        case "P2P":
            Pushgtagdata(
                "P2P_Listing​",
                `Launch ${providerCode}` ,
                `P2P_Listing_C_Vendor`,
                "",
                [
                    {customVariableKey: `P2P_Listing_C_VendorName`,
                    customVariableValue: providerCode},
                ]
            );
            break;
        case "Slot":
            Pushgtagdata(
                "SlotFishing_Listing​",
                `Go to ${providerCode} Lobby` ,
                `SlotFishing_Listing_C_${providerCode}`,
            );
            break;
        case "KenoLottery":
            Pushgtagdata(
                "Lottery_Listing​",
                `Go to ${providerCode} Lobby​`,
                `Lottery_Listing_C_${providerCode}`,
            );
        default:
            break;
    }
}

/*游戏列表页、（无搜索游戏功能页）游戏类型 PIWIK
* @param {*} gameCatCode  游戏类型code
* @param {*} categoryType  类型
* @returns 
*/
export const gameListPageGamesTypePiwik =(gameCatCode,categoryType)=>{
    switch(gameCatCode){
        case "LiveCasino":
            Pushgtagdata(
                "LiveDealer_Listing​",
                `Launch ${categoryType}` ,
                `LiveDealer_Listing_C_GameType`,
                "",
                [
                    {customVariableKey: `LiveDealer_Listing_C_Filter_GameTypeName`,
                    customVariableValue: categoryType},
                ]
            );
            break;
        case "P2P":
            Pushgtagdata(
                "P2P_Listing​",
                `Go to P2P Lobby​` ,
                `P2P_Listing_C_GameType`,
                "",
                [
                    {customVariableKey: `P2P_Listing_C_GameTypeName`,
                    customVariableValue: categoryType},
                ]
            );
            break;
        case "Slot":
            Pushgtagdata(
                "SlotFishing_Listing​",
                `Go to Slot Fishing Lobby​​` ,
                `SlotFishing_Listing_C_Filter​`,
                "",
                [
                    {customVariableKey: `Slots_Listing_C_Filter_GameTypeName`,
                    customVariableValue: categoryType},
                ]
            );
            break;
        default:
            break;
    }
}

/**
 * 游戏列表页（可搜索游戏页），游戏类型 Piwik
 * @param {*} gameCatCode 
 * @param {*} categoryType 
 */
export const gameListProductPageTypePiwik =(
    gameCatCode,
    categoryType
) =>{
    console.log("🚀 ~ categoryType:", categoryType)
    switch(gameCatCode){
        case "LiveCasino":
            Pushgtagdata(
                "LiveDealer_Lobby",
                "Filter Game​",
                `LiveDealer_Lobby_C_GameType​`,
                "",
                [
                    {customVariableKey: `LiveDealer_Lobby_C_GameType`,
                    customVariableValue: categoryType},
                ]
        
            );
            break;
        case "P2P":
            Pushgtagdata(
                "P2P_Lobby",
                "Filter Game​",
                `P2P_Lobby_C_GameType​`,
                "",
                [
                    {customVariableKey: `P2P_Lobby_C_GameType`,
                    customVariableValue: categoryType},
                ]
        
            );
            break;
        case "Slot":
            Pushgtagdata(
                "SlotFishing_Lobby",
                "Filter Game​",
                `SlotFishing_Lobby_C_GameType​`,
                "",
                [
                    {customVariableKey: `SlotFishing_Lobby_C_GameType​`,
                    customVariableValue: categoryType},
                ]
        
            );
            break;
        case "KenoLottery":
            Pushgtagdata(
                "Lottery_Lobby",
                "Filter Game​",
                `Lottery_Lobby_C_GameType​​`,
                "",
                [
                    {customVariableKey: `SlotFishing_Lobby_C_GameType​`,
                    customVariableValue: categoryType},
                ]
        
            );
            break;
        default:
            break;
    }
}